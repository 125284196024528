<template>
  <div class="workArea">
    <div
      class="item"
      v-for="(item, index) in listData"
      :key="index"
      @click="toList(item)"
    >
      <div class="item-t">
        <div class="name">{{ item.name }}</div>
        <div :class="('status', { active: item.rent == '未出租' })">
          {{ item.rent }}
        </div>
      </div>
      <div class="item-c">
        <div class="name">{{ item.orgName }}</div>
        <div class="address">
          {{ item.path }}
        </div>
        <div class="decoration">{{ item.decoration }}</div>
      </div>
      <div class="item-b"></div>
    </div>
  </div>
</template>

<script>
import { workAreaListUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "createWork",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      form: {
        donatePic: "",
        donateContent: "",
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {
    this.getCreateWork();
  },
  methods: {
    toList(item) {
      this.$router.push({
        name: "workAreaForm",
        query: {
          spaceId: item.spaceId,
        },
      });
    },

    getCreateWork() {
      let params = {
        spaceId: "P1C2A2S1D1V14B9L2",
        type: 22,
      };
      this.$axios.get(`${workAreaListUrl}`, { params: params }).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.workArea {
  background: #fefefe;
  min-height: 100vh;
  .item {
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    margin-bottom: 20px;
    .item-t {
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      .active {
        color: #009aff;
      }
    }
    .item-c {
      color: #999;
      font-size: 28px;
      .name,
      .address,
      .decoration {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="less">
.workArea {
}
</style>
